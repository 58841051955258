footer #freestar-container {
  padding: 16px 0px 10px 0px; }

footer #social-container {
  background-color: #f8f8f8; }
  footer #social-container h4 {
    color: black;
    font-weight: bold; }
  footer #social-container p {
    color: black; }
  footer #social-container a,
  footer #social-container a:hover {
    text-decoration: none; }
  footer #social-container .fa {
    padding-right: 10px; }
  footer #social-container .emailsignupblock .footer-form-group {
    width: 272px; }
    footer #social-container .emailsignupblock .footer-form-group .footer-email-address-input {
      width: 200px; }
    footer #social-container .emailsignupblock .footer-form-group .btn-success {
      background-color: #478447; }
  footer #social-container .emailsignupblock .email-signup-validation {
    margin-top: 5px; }

footer #links-container {
  background-color: #fff;
  padding: 25px 0; }
  footer #links-container h5 {
    color: black;
    font-size: 16px;
    font-weight: bold; }
  footer #links-container li {
    padding-bottom: 5px; }
  footer #links-container a,
  footer #links-container a:hover {
    color: black;
    padding: 0; }

footer .be-ix-link-block .be-related-link-container {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center; }
  footer .be-ix-link-block .be-related-link-container .be-label {
    font-variant-numeric: lining-nums;
    color: #000;
    font-size: 16px;
    font-weight: 700; }
  footer .be-ix-link-block .be-related-link-container .be-list {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0; }
    footer .be-ix-link-block .be-related-link-container .be-list .be-list-item {
      display: inline-block;
      margin-bottom: 6px; }

footer #partners-container {
  border-top: 1px solid #e4dece;
  border-bottom: 1px solid #e4dece;
  padding: 15px 0; }
  footer #partners-container .footerLogos > * {
    padding: 0% 2%; }
    footer #partners-container .footerLogos > * h5 {
      font-size: 12px;
      text-transform: uppercase; }
    footer #partners-container .footerLogos > * sup {
      margin-right: 15px; }
    footer #partners-container .footerLogos > * a,
    footer #partners-container .footerLogos > * a:hover {
      text-decoration: none; }

footer #copyright-container {
  color: black;
  background-color: #fff;
  font-size: 12px;
  margin: 15px 0; }

footer #email {
  min-width: 190px; }

@media only screen and (max-width: 1024px) {
  .mobile-footer {
    background-color: #f8f8f8;
    text-align: center; }
    .mobile-footer .top-links,
    .mobile-footer .bottom-links {
      -ms-flex-align: center;
          align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between; }
    .mobile-footer .top-links {
      margin-bottom: 10px; }
    .mobile-footer .top-link,
    .mobile-footer .bottom-list-item {
      -ms-flex: auto;
          flex: auto; }
    .mobile-footer .top-fa-icon:before {
      font-size: 1.7em; }
    .mobile-footer .bottom-link,
    .mobile-footer .catalog-button,
    .mobile-footer .copyright-row,
    .mobile-footer .security-icon-header,
    .mobile-footer .social-icons .row:first-child p,
    .mobile-footer .top-link-text {
      font-weight: 700; }
    .mobile-footer .bottom-link,
    .mobile-footer .copyright-row,
    .mobile-footer .security-icon-header,
    .mobile-footer .social-icons .row:first-child p {
      color: #00573d; }
    .mobile-footer .link-divider {
      color: #4d7c6e; }
    .mobile-footer .catalog-button {
      font-size: 16px;
      padding: 4px; }
    .mobile-footer .link-divider {
      background-color: #4d7c6e;
      border: 0;
      height: 2px;
      margin-bottom: 5px;
      margin-top: 10px; }
    .mobile-footer .bottom-links {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .mobile-footer .bottom-links > .bottom-list-item {
        height: 25px; }
    .mobile-footer .social-icons,
    .mobile-footer .security-icons {
      margin-top: 25px; }
    .mobile-footer .social-icons .socialmediaiconlistblock > a:not(:last-child) {
      margin-right: 10px; }
    .mobile-footer .security-icons > a:not(:last-child) {
      margin-right: 12px; }
    .mobile-footer .security-link {
      display: inline-block; }
    .mobile-footer .copyright-row {
      font-size: 12px;
      margin: 25px 0; }
    .mobile-footer .freestar-row {
      padding: 0px 0px 10px 0px; }
    .mobile-footer .be-ix-link-block .be-related-link-container {
      -ms-flex-flow: column;
          flex-flow: column; }
      .mobile-footer .be-ix-link-block .be-related-link-container .be-label {
        font-size: 14px;
        font-weight: 700;
        line-height: 2; }
      .mobile-footer .be-ix-link-block .be-related-link-container .be-list-item {
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding: 0 2%;
        margin-right: 0; } }

@media only screen and (min-width: 1025px) {
  footer #social-container {
    height: 200px;
    padding: 25px 0; }
    footer #social-container > .container > .row > [class^="col-"],
    footer #social-container > .container > .row [class*=" col-"] {
      height: 150px; }
    footer #social-container > .container > .row > [class*=" col-"]:first-of-type {
      padding-right: 25px; }
    footer #social-container > .container > .row > [class^="col-"]:nth-of-type(2),
    footer #social-container > .container > .row > [class*=" col-"]:nth-of-type(2) {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff; }
    footer #social-container > .container > .row > [class*=" col-"]:last-of-type {
      padding-left: 30px; }
  footer #links-container .link-item-collection {
    display: table;
    margin: 0 auto;
    text-align: left; }
  footer .be-related-link-container {
    -ms-flex-flow: row;
        flex-flow: row; }
    footer .be-related-link-container .be-label {
      line-height: 1; }
    footer .be-related-link-container .be-list-item {
      margin-right: 0; } }
