footer {
    #freestar-container {
        padding: 16px 0px 10px 0px;
    }

    #social-container {
        background-color: $lighter-gray;

        h4 {
            color: black;
            font-weight: bold;
        }

        p {
            color: black;
        }

        a,
        a:hover {
            text-decoration: none;
        }

        .fa {
            padding-right: 10px;
        }

        .emailsignupblock {
            .footer-form-group {
                width: 272px;

                .footer-email-address-input {
                    width: 200px;
                }

                .btn-success {
                    background-color: $almost-medium-green;
                }
            }

            .email-signup-validation {
                margin-top: 5px;
            }
        }
    }

    #links-container {
        background-color: $white;
        padding: 25px 0;

        h5 {
            color: black;
            font-size: 16px;
            font-weight: bold;
        }

        li {
            padding-bottom: 5px;
        }

        a,
        a:hover {
            color: black;
            padding: 0;
        }
    }

    .be-ix-link-block .be-related-link-container {
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;

    
        .be-label {
                 
            font-variant-numeric: lining-nums;
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    
         .be-list {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;
            
              .be-list-item {
                display: inline-block;
                
                margin-bottom: 6px;
              }
         }
    }
     

    #partners-container {
        border-top: 1px solid $lighter-beige;
        border-bottom: 1px solid $lighter-beige;
        padding: 15px 0;

        .footerLogos > * {
            padding: 0% 2%;

            h5 {
                font-size: $font-size-small;
                text-transform: uppercase;
            }

            sup {
                margin-right: 15px;
            }

            a,
            a:hover {
                text-decoration: none;
            }
        }
    }

    #copyright-container {
        color: black;
        background-color: $white;
        font-size: $font-size-small;
        margin: 15px 0;
    }

    #email {
        min-width: 190px;
    }
}

// mobile and tablets
@media only screen and (max-width: 1024px) {

    .mobile-footer {
        background-color: $lighter-gray;
        text-align: center;

        .top-links,
        .bottom-links {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .top-links {
            margin-bottom: 10px;
        }

        .top-link,
        .bottom-list-item {
            flex: auto;
        }

        .top-fa-icon:before {
            font-size: 1.7em;
        }

        .bottom-link,
        .catalog-button,
        .copyright-row,
        .security-icon-header,
        .social-icons .row:first-child p,
        .top-link-text {
            font-weight: 700;
        }

        .bottom-link,
        .copyright-row,
        .security-icon-header,
        .social-icons .row:first-child p {
            color: $collections-green;
        }

        .link-divider {
            color: $green;
        }

        .catalog-button {
            font-size: 16px;
            padding: 4px;
        }

        .link-divider {
            background-color: $green;
            border: 0;
            height: 2px;

            margin: {
                bottom: 5px;
                top: 10px;
            }
        }

        .bottom-links {
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;

            > .bottom-list-item {
                height: 25px;
            }
        }

        .social-icons,
        .security-icons {
            margin-top: 25px;
        }

        .social-icons {
            .socialmediaiconlistblock {
                > a:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        .security-icons {
            > a:not(:last-child) {
                margin-right: 12px;
            }
        }

        .security-link {
            display: inline-block;
        }

        .copyright-row {
            font-size: $font-size-small;
            margin: 25px 0;
        }

        .freestar-row {
            padding: 0px 0px 10px 0px;
        }

        .be-ix-link-block .be-related-link-container {
            flex-flow: column;

            .be-label {
                font-size: 14px;
                font-weight: 700;
                line-height:2;
            }

            .be-list-item {
                display: inline-flex;
                padding: 0 2%;
                margin-right: 0;
            }
        }
    }
}
// desktops
@media only screen and (min-width: 1025px) {

    footer {
        #social-container {
            height: 200px;
            padding: 25px 0;

            > .container > .row {
                > [class^="col-"],
                [class*=" col-"] {
                    height: 150px;
                }

                > [class*=" col-"]:first-of-type {
                    padding-right: 25px;
                }

                > [class^="col-"]:nth-of-type(2),
                > [class*=" col-"]:nth-of-type(2) {
                    border-left: 1px solid $white;
                    border-right: 1px solid $white;
                }

                > [class*=" col-"]:last-of-type {
                    padding-left: 30px;
                }
            }
        }

        #links-container {
            .link-item-collection {
                display: table;
                margin: 0 auto;
                text-align: left;
            }
        }

        .be-related-link-container {
            flex-flow: row;

            .be-label {
                line-height: 1;
            }

            .be-list-item {
                margin-right: 0;
            }
        }
    }
}
